import {
	createContext,
	Dispatch,
	ReactNode,
	SetStateAction,
	useEffect,
	useState,
} from "react"
import { Polygons } from "../types/Polygons"

export type Data = {
	files: string[] | null
	setFiles: Dispatch<SetStateAction<string[] | null>>
	fileNames: string[] | null
	setFileNames: Dispatch<SetStateAction<string[] | null>>
	selectedFileIndex: number
	setSelectedFileIndex: Dispatch<SetStateAction<number>>
	polygons: Polygons[]
	setPolygons: Dispatch<SetStateAction<Polygons[]>>
	selectedPolygon: {
		type: keyof Polygons
		index: number
		fileIndex: number
	}
	setSelectedPolygon: Dispatch<
		SetStateAction<{
			type: keyof Polygons
			index: number
			fileIndex: number
		}>
	>
	showAll: boolean
	setShowAll: Dispatch<SetStateAction<boolean>>
	szs: { cw: number; ch: number }
	setSzs: Dispatch<SetStateAction<{ cw: number; ch: number }>>
}

export const DataContext = createContext<Data>({
	files: null,
	setFiles: () => {},
	fileNames: null,
	setFileNames: () => {},
	selectedFileIndex: 0,
	setSelectedFileIndex: () => {},
	polygons: [
		{
			facades: [],
			roofs: [],
			doors: [],
			windows: [],
		},
	],
	setPolygons: () => {},
	selectedPolygon: {
		type: "facades",
		index: 0,
		fileIndex: 0,
	},
	setSelectedPolygon: () => {},
	showAll: false,
	setShowAll: () => {},
	szs: { cw: 0, ch: 0 },
	setSzs: () => {},
})

export const DataProvider = ({ children }: { children: ReactNode }) => {
	const [files, setFiles] = useState<string[] | null>(null)
	const [fileNames, setFileNames] = useState<string[] | null>(null)
	const [selectedFileIndex, setSelectedFileIndex] = useState<number>(0)
	const [polygons, setPolygons] = useState<Polygons[]>([
		{
			facades: [],
			roofs: [],
			doors: [],
			windows: [],
		},
	])
	const [selectedPolygon, setSelectedPolygon] = useState<{
		type: keyof Polygons
		index: number
		fileIndex: number
	}>({
		type: "facades",
		index: 0,
		fileIndex: 0,
	})
	const [showAll, setShowAll] = useState(false)
	const [szs, setSzs] = useState({ cw: 0, ch: 0 })

	useEffect(() => {
		if (showAll)
			setSelectedPolygon({ type: "facades", index: -1, fileIndex: -1 })
	}, [showAll])

	useEffect(() => {
		if (files) {
			setPolygons(
				files.map(() => ({
					facades: [],
					roofs: [],
					doors: [],
					windows: [],
				}))
			)
		}
	}, [files])

	useEffect(() => {
		setSelectedPolygon({
			type: "facades",
			index: 0,
			fileIndex: selectedFileIndex,
		})
	}, [selectedFileIndex])

	return (
		<DataContext.Provider
			value={{
				files,
				setFiles,
				fileNames,
				setFileNames,
				selectedFileIndex,
				setSelectedFileIndex,
				polygons,
				setPolygons,
				selectedPolygon,
				setSelectedPolygon,
				showAll,
				setShowAll,
				szs,
				setSzs,
			}}
		>
			{children}
		</DataContext.Provider>
	)
}

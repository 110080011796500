import Header from "./components/Header"
import ControlPanel from "./components/ControlPanel"
import { DataProvider } from "./components/DataContext"
import ImageViewer from "./components/ImageViewer"
import Gallery from "./components/Gallery"
import ShortcutListener from "./components/ShortutListener"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
	return (
		<DataProvider>
			<main className="grid h-screen w-screen grid-cols-12 grid-rows-12 overflow-hidden">
				<header className="col-span-10 row-span-1 bg-slate-100">
					<Header />
				</header>
				<section className="col-span-2 row-span-12 bg-slate-100">
					<ControlPanel />
				</section>
				<section className="col-span-10 col-start-1 row-span-10 row-start-2 bg-slate-200">
					<ImageViewer />
				</section>
				<footer className="col-span-10 col-start-1 row-span-1 row-start-12 bg-slate-100">
					<Gallery />
				</footer>
			</main>
			<ShortcutListener />
			<ToastContainer />
		</DataProvider>
	)
}

export default App

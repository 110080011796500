import React, { useContext } from "react"
import { DataContext } from "../components/DataContext"

const useFormats = () => {
	const { fileNames, selectedFileIndex, polygons, szs } =
		useContext(DataContext)
	const getCoco = () => {
		if (!fileNames) return
		let str = ""
		polygons[selectedFileIndex].facades.forEach((polygon) => {
			str += `0 `
			polygon.forEach((coord) => {
				const rx = Math.round((coord.x / szs.cw) * 100000) / 100000
				const ry = Math.round((coord.y / szs.ch) * 100000) / 100000
				str += `${rx} ${ry} `
			})
			str += "\n"
		})
		polygons[selectedFileIndex].roofs.forEach((polygon) => {
			str += `1 `
			polygon.forEach((coord) => {
				const rx = Math.round((coord.x / szs.cw) * 100000) / 100000
				const ry = Math.round((coord.y / szs.ch) * 100000) / 100000
				str += `${rx} ${ry} `
			})
			str += "\n"
		})
		polygons[selectedFileIndex].doors.forEach((polygon) => {
			str += `2 `
			polygon.forEach((coord) => {
				const rx = Math.round((coord.x / szs.cw) * 100000) / 100000
				const ry = Math.round((coord.y / szs.ch) * 100000) / 100000
				str += `${rx} ${ry} `
			})
			str += "\n"
		})
		polygons[selectedFileIndex].windows.forEach((polygon) => {
			str += `3 `
			polygon.forEach((coord) => {
				const rx = Math.round((coord.x / szs.cw) * 100000) / 100000
				const ry = Math.round((coord.y / szs.ch) * 100000) / 100000
				str += `${rx} ${ry} `
			})
			str += "\n"
		})

		return str
	}

	return { getCoco }
}

export default useFormats

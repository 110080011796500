import React, { useContext, useEffect, useRef, useState } from "react"
import { DataContext } from "./DataContext"
import { Coord } from "../types/Coord"
import { PolygonStrokeColors } from "../types/PolygonColors"

const ImageViewer = () => {
	const {
		files,
		selectedFileIndex,
		polygons,
		setPolygons,
		selectedPolygon,
		showAll,
		szs,
		setSzs,
	} = useContext(DataContext)
	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null)
	const [_, setCoords] = useState<Array<Coord>>(
		(selectedPolygon &&
			selectedFileIndex &&
			polygons[selectedFileIndex][selectedPolygon.type][
				selectedPolygon.index
			]) ||
			[]
	)

	useEffect(() => {
		if (!files) {
			setSzs({ cw: 0, ch: 0 })
			return
		}
		setCoords([])
		const image = document.getElementById("image") as HTMLImageElement
		if (image) {
			image.onload = () => {
				setSzs({ cw: image.width, ch: image.height })
			}
		}
	}, [files, selectedFileIndex])

	useEffect(() => {
		if (!canvasRef.current || !files) return
		const canvas = canvasRef.current
		const context = canvas.getContext("2d")
		if (context) {
			setCtx(context)
			if (!ctx) return
			ctx.lineWidth = 2
			ctx.clearRect(0, 0, szs.cw, szs.ch)
		}
	}, [files, selectedFileIndex, szs, ctx])

	useEffect(() => {
		if (!ctx || !selectedPolygon || selectedPolygon.fileIndex === -1) return
		ctx.clearRect(0, 0, szs.cw, szs.ch)
		setCoords(
			polygons[selectedPolygon.fileIndex][selectedPolygon.type][
				selectedPolygon.index
			]
		)
		drawPolygon(
			polygons[selectedPolygon.fileIndex][selectedPolygon.type][
				selectedPolygon.index
			]
		)
	}, [selectedPolygon, ctx, polygons, szs])

	useEffect(() => {
		if (!canvasRef.current || !files || !ctx) return
		if (showAll) {
			renderPreview()
		} else {
			ctx.clearRect(0, 0, szs.cw, szs.ch)
		}
		// eslint-disable-next-line
	}, [showAll, ctx, files, szs])

	const handleMouseDown = (e: React.MouseEvent) => {
		if (!canvasRef.current || !ctx) return
		e.preventDefault()
		e.stopPropagation()
		const rect = canvasRef.current.getBoundingClientRect()
		const x = e.clientX - rect.left
		const y = e.clientY - rect.top

		setCoords((prevCoords) => {
			const newCoords = [...(prevCoords || []), { x, y }]
			ctx.clearRect(0, 0, szs.cw, szs.ch)
			drawPolygon(newCoords)
			setPolygons((prevPolygons) => {
				if (!selectedPolygon) return prevPolygons
				const newPolygons = { ...prevPolygons }
				newPolygons[selectedFileIndex][selectedPolygon.type][
					selectedPolygon.index
				] = newCoords
				return newPolygons
			})
			return newCoords
		})
	}

	const drawPolygon = (
		newCoords: Array<Coord>,
		color: string = selectedPolygon
			? PolygonStrokeColors[selectedPolygon.type]
			: "white"
	) => {
		if (!canvasRef.current || !ctx || !newCoords) return
		ctx.strokeStyle = color
		ctx.fillStyle = color
		if (newCoords.length > 1) {
			ctx.beginPath()
			ctx.moveTo(newCoords[0].x, newCoords[0].y)
			newCoords.forEach((coord) => {
				ctx.lineTo(coord.x, coord.y)
			})
			ctx.lineTo(newCoords[0].x, newCoords[0].y)
			ctx.stroke()
			newCoords.forEach((coord) => {
				ctx.beginPath()
				ctx.arc(coord.x, coord.y, 3, 0, 2 * Math.PI)
				ctx.fill()
			})
		}
	}

	const renderPreview = () => {
		if (!canvasRef.current || !ctx) return
		if (!ctx) return
		const canvas = canvasRef.current
		ctx.lineWidth = 2
		ctx.strokeStyle = selectedPolygon
			? PolygonStrokeColors[selectedPolygon.type]
			: "white"
		ctx.fillStyle = selectedPolygon
			? PolygonStrokeColors[selectedPolygon.type]
			: "white"
		ctx.clearRect(0, 0, canvas.width, canvas.height)
		if (showAll) {
			if (selectedFileIndex === -1) return
			const polygonKeys = Object.keys(polygons[selectedFileIndex]) as Array<
				keyof (typeof polygons)[typeof selectedFileIndex]
			>
			for (const key of polygonKeys) {
				polygons[selectedFileIndex][key].forEach((polygon: Coord[]) => {
					if (polygon.length > 1) {
						drawPolygon(polygon, PolygonStrokeColors[key])
					}
				})
			}
		}
	}

	return (
		<div className="relative h-full w-full p-3">
			{files && (
				<img
					id="image"
					src={files[selectedFileIndex || 0]}
					alt={files[selectedFileIndex || 0]}
					className="absolute left-1/2 top-1/2 h-auto max-h-full w-auto max-w-full -translate-x-1/2 -translate-y-1/2 border-2 border-black"
				/>
			)}
			<canvas
				ref={canvasRef}
				className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-auto max-h-full w-auto max-w-full w-[${szs.cw}px] h-[${szs.ch}px] z-10`}
				width={szs.cw}
				height={szs.ch}
				id="canvas"
				onMouseDown={(e) => {
					if (showAll) return
					handleMouseDown(e as unknown as React.MouseEvent)
				}}
			></canvas>
		</div>
	)
}

export default ImageViewer

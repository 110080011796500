import { useContext } from "react"
import { Container, Form } from "react-bootstrap"
import { DataContext } from "./DataContext"
import useExport from "../hooks/useExport"

const ControlPanel = () => {
	const {
		files,
		setFiles,
		fileNames,
		setFileNames,
		selectedFileIndex,
		polygons,
		setPolygons,
		selectedPolygon,
		setSelectedPolygon,
		showAll,
		setShowAll,
	} = useContext(DataContext)

	const { exportJson, exportCoco, uploadToCdn } = useExport()

	return (
		<Container className="flex h-screen flex-col gap-2 overflow-y-auto p-4 text-sm">
			<section>
				<Form>
					<Form.Group>
						<Form.Label className="block">Vyberte soubor (O)</Form.Label>
						<input
							id="file"
							type="file"
							multiple
							className="text-sm"
							onChange={(e) => {
								if (e.target.files) {
									setFileNames(
										Array.from(e.target.files).map((file) => file.name)
									)
									setFiles(
										Array.from(e.target.files).map((file) =>
											URL.createObjectURL(file)
										)
									)
								}
							}}
						/>
					</Form.Group>
				</Form>
			</section>
			{/* <section className="text-xs">
				<code>{files}</code>
			</section> */}
			<button
				className={`block rounded border border-slate-200 p-[2px] ${
					showAll
						? "bg-red-100 hover:bg-red-200"
						: "bg-emerald-100 hover:bg-emerald-200"
				}`}
				onClick={() => {
					setShowAll(!showAll)
				}}
			>
				{showAll ? "Skrýt vše" : "Zobrazit vše"} (Q)
			</button>

			{files && (
				<section className="flex flex-col gap-1">
					{(
						[
							"facades",
							"roofs",
							"doors",
							"windows",
						] as (keyof (typeof polygons)[0])[]
					).map((key, index) => {
						return (
							<section
								key={key}
								className="rounded border border-slate-200 bg-white"
							>
								<header className="p-2">
									<h2 className="text-base">{key}</h2>
								</header>
								<ul className="flex flex-wrap items-center gap-1">
									{polygons[selectedFileIndex][key].map((polygon, index) => (
										<li
											key={index}
											className={`flex flex-col items-center py-[2px] px-[8px] rounded border border-slate-200 hover:bg-slate-100 cursor-pointer ${
												selectedPolygon &&
												selectedPolygon.type === key &&
												selectedPolygon.index === index
													? "bg-slate-100 hover:bg-slate-200"
													: ""
											}`}
											onClick={() => {
												const p = polygons[selectedFileIndex]
												const type = key as keyof typeof p
												setSelectedPolygon({
													type,
													index,
													fileIndex: selectedFileIndex,
												})
											}}
											onContextMenu={(e) => {
												e.preventDefault()
												setPolygons({
													...polygons,
													[selectedFileIndex]: {
														...polygons[selectedFileIndex],
														[key]: polygons[selectedFileIndex][key].filter(
															(_, i) => i !== index
														),
													},
												})
											}}
										>
											<span>{index + 1}</span>
											<div className="flex gap-1">
												{/* {polygon.map((coord) => (
													<div
														key={index}
														className="flex h-full w-auto max-w-full flex-col flex-wrap items-center"
													>
														<code className="flex flex-col text-[8px]">
															<span>{coord.x}</span>
															<span>{coord.y}</span>
														</code>
													</div>
												))} */}
											</div>
										</li>
									))}
									<li>
										<button
											className="block rounded border border-slate-200 p-[2px] hover:bg-slate-100"
											onClick={() => {
												setSelectedPolygon({
													type: key,
													index: polygons[selectedFileIndex][key].length,
													fileIndex: selectedFileIndex,
												})
												setPolygons({
													...polygons,
													[selectedFileIndex]: {
														...polygons[selectedFileIndex],
														[key]: [...polygons[selectedFileIndex][key], []],
													},
												})
											}}
										>
											Přidat ({["Y", "X", "C", "V"][index]})
										</button>
									</li>
								</ul>
							</section>
						)
					})}
				</section>
			)}
			<section className="flex w-full justify-between">
				<button
					className={"block rounded border border-slate-200 bg-white p-[2px]"}
					onClick={() => {
						exportJson()
					}}
				>
					Export JSON (J)
				</button>

				<button
					className={"block rounded border border-slate-200 bg-white p-[2px]"}
					onClick={() => {
						exportCoco()
					}}
				>
					Export COCO (K)
				</button>
			</section>
			<section className="flex w-full justify-between">
				<button
					className={
						"block rounded border border-slate-200 bg-white p-[2px] w-full"
					}
					onClick={() => {
						uploadToCdn()
					}}
				>
					CDN upload (T)
				</button>
			</section>
		</Container>
	)
}

export default ControlPanel

import React from "react"
import { Container, Navbar } from "react-bootstrap"

const Header = () => {
	return (
		<Navbar className="h-full">
			<Container className="h-full">
				<Navbar.Brand
					href="#home"
					className="flex h-full items-center !text-indigo-800"
				>
					Refsite GeometryAI anotátor
				</Navbar.Brand>
			</Container>
		</Navbar>
	)
}

export default Header

import React, { useContext } from "react"
import { DataContext } from "./DataContext"
import { Container } from "react-bootstrap"

const Gallery = () => {
	const { files, selectedFileIndex, setSelectedFileIndex } =
		useContext(DataContext)
	return (
		<Container className="flex h-full items-center gap-1">
			{files ? (
				<>
					{files.map((file, index) => (
						<img
							alt={file}
							className={`h-16 w-16 object-cover object-center cursor-pointer ${
								selectedFileIndex === index ? "" : "opacity-50"
							}`}
							src={file}
							onClick={() => setSelectedFileIndex(index)}
						/>
					))}
				</>
			) : (
				<span>Žádné obrázky</span>
			)}
		</Container>
	)
}

export default Gallery

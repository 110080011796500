export enum PolygonColors {
	facades = "slate-500",
	roofs = "red-500",
	doors = "green-500",
	windows = "blue-500",
}

export enum PolygonStrokeColors {
	facades = "white",
	roofs = "red",
	doors = "green",
	windows = "blue",
}

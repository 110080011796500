import React, { useContext, useEffect } from "react"
import { DataContext } from "./DataContext"
import useExport from "../hooks/useExport"

const ShortcutListener = () => {
	const {
		files,
		selectedFileIndex,
		setSelectedFileIndex,
		showAll,
		setShowAll,
		polygons,
		setPolygons,
		selectedPolygon,
		setSelectedPolygon,
	} = useContext(DataContext)
	const { exportJson, exportCoco, uploadToCdn } = useExport()
	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key.toLowerCase() === "o") {
			const fileInput = document.getElementById("file") as HTMLInputElement
			fileInput.click()
		}
		if (e.key === "ArrowLeft") {
			setSelectedFileIndex(selectedFileIndex === 0 ? 0 : selectedFileIndex - 1)
		}
		if (e.key === "ArrowRight") {
			if (files) {
				setSelectedFileIndex(
					selectedFileIndex === files.length - 1
						? files.length - 1
						: selectedFileIndex + 1
				)
			}
		}
		if (e.key.toLowerCase() === "q") {
			setShowAll(!showAll)
		}
		if (["y", "z", "x", "c", "v"].includes(e.key.toLowerCase())) {
			const key2type = (key: string) => {
				switch (key) {
					case "y":
						return "facades"
					case "z":
						return "facades"
					case "x":
						return "roofs"
					case "c":
						return "doors"
					case "v":
						return "windows"
					default:
						return "facades"
				}
			}
			setPolygons({
				...polygons,
				[selectedFileIndex]: {
					...polygons[selectedFileIndex],
					[key2type(e.key.toLowerCase())]: [
						...polygons[selectedFileIndex][key2type(e.key.toLowerCase())],
						[],
					],
				},
			})
			setSelectedPolygon({
				type: key2type(e.key.toLowerCase()),
				index:
					polygons[selectedFileIndex][key2type(e.key.toLowerCase())].length,
				fileIndex: selectedFileIndex,
			})
		}
		if (["w", "a", "s", "d"].includes(e.key.toLowerCase())) {
			if (!selectedPolygon || showAll) return
			const { type, index, fileIndex } = selectedPolygon
			const typeIndex = ["facades", "roofs", "doors", "windows"].indexOf(type)
			switch (e.key.toLowerCase()) {
				case "a":
					setSelectedPolygon({
						...selectedPolygon,
						index:
							index === 0 ? polygons[fileIndex][type].length - 1 : index - 1,
					})
					break
				case "d":
					setSelectedPolygon({
						...selectedPolygon,
						index:
							index === polygons[fileIndex][type].length - 1 ? 0 : index + 1,
					})
					break
				case "w":
					let shiftLeftType = ["facades", "roofs", "doors", "windows"][
						typeIndex === 0 ? 3 : typeIndex - 1
					] as keyof (typeof polygons)[0]
					setSelectedPolygon({
						...selectedPolygon,
						type: shiftLeftType,
						index:
							index >= polygons[fileIndex][shiftLeftType].length
								? polygons[fileIndex][shiftLeftType].length - 1
								: index,
					})
					break
				case "s":
					let shiftRightType = ["facades", "roofs", "doors", "windows"][
						typeIndex === 3 ? 0 : typeIndex + 1
					] as keyof (typeof polygons)[0]
					setSelectedPolygon({
						...selectedPolygon,
						type: shiftRightType,
						index:
							index >= polygons[fileIndex][shiftRightType].length
								? polygons[fileIndex][shiftRightType].length - 1
								: index,
					})
					break
			}
		}
		if (["j", "k"].includes(e.key.toLowerCase())) {
			if (!files) return
			if (e.key.toLowerCase() === "j") {
				exportJson()
			}
			if (e.key.toLowerCase() === "k") {
				exportCoco()
			}
		}
		if (e.key === "Delete") {
			const key = selectedPolygon.type
			const index = selectedPolygon.index
			setPolygons({
				...polygons,
				[selectedFileIndex]: {
					...polygons[selectedFileIndex],
					[key]: polygons[selectedFileIndex][key].filter((_, i) => i !== index),
				},
			})
		}
		if (e.key.toLowerCase() === "t") {
			uploadToCdn()
		}
	}
	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown)
		return () => {
			window.removeEventListener("keydown", handleKeyDown)
		}
	})

	return <></>
}

export default ShortcutListener

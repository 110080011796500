import React, { useContext } from "react"
import { DataContext } from "../components/DataContext"
import useFormats from "./useFormats"
import { toast } from "react-toastify"

const useExport = () => {
	const { fileNames, files, selectedFileIndex, polygons, szs } =
		useContext(DataContext)
	const { getCoco } = useFormats()
	const exportJson = () => {
		if (!fileNames) return
		const data = {
			filename: fileNames[selectedFileIndex],
			width: szs.cw,
			height: szs.ch,
			polygons: polygons[selectedFileIndex],
		}
		const blob = new Blob([JSON.stringify(data)], { type: "application/json" })
		const url = URL.createObjectURL(blob)
		const a = document.createElement("a")
		a.href = url
		const jsonFileName = `${fileNames[selectedFileIndex]
			.replaceAll(".jpg", "")
			.replaceAll(".png", "")
			.replaceAll(".jpeg", "")
			.replaceAll(".JPG", "")
			.replaceAll(".PNG", "")
			.replaceAll(".JPEG", "")}.json`
		a.download = jsonFileName
		a.click()
		URL.revokeObjectURL(url)
	}

	const exportCoco = () => {
		const coco = getCoco()
		if (!coco || !fileNames) return
		const blob = new Blob([coco], { type: "text/plain" })
		const url = URL.createObjectURL(blob)
		const a = document.createElement("a")
		a.href = url
		const cocoFileName = `${fileNames[selectedFileIndex]
			.replaceAll(".jpg", "")
			.replaceAll(".png", "")
			.replaceAll(".jpeg", "")
			.replaceAll(".JPG", "")
			.replaceAll(".PNG", "")
			.replaceAll(".JPEG", "")}.txt`
		a.download = cocoFileName
		a.click()
		URL.revokeObjectURL(url)
	}

	const uploadToCdn = async () => {
		const coco = getCoco()
		if (!coco || !fileNames || !files) return
		const cocoBlob = new Blob([coco], { type: "text/plain" })
		const imgBlob = await fetch(files[selectedFileIndex]).then((res) =>
			res.blob()
		)
		const formData = new FormData()
		formData.append("folder", "geoai")
		formData.append(
			"file",
			cocoBlob,
			`${fileNames[selectedFileIndex]
				.replaceAll(".jpg", "")
				.replaceAll(".png", "")
				.replaceAll(".jpeg", "")
				.replaceAll(".JPG", "")
				.replaceAll(".PNG", "")
				.replaceAll(".JPEG", "")}.txt`
		)
		formData.append("file", imgBlob, fileNames[selectedFileIndex])
		formData.append("useOriginalName", "true")
		const cdnUrl = "https://cdn.refsite.info/uploadFiles"
		if (!cdnUrl) {
			toast.error("CDN URL is not defined")
			return
		}
		await fetch(cdnUrl, {
			method: "POST",
			body: formData,
		})
			.then((res) => {
				if (res.ok) {
					toast.success("Uploaded to CDN")
				} else {
					toast.error("Failed to upload to CDN")
				}
			})
			.catch((err) => {
				toast.error("Failed to upload to CDN")
			})
	}

	return { exportJson, exportCoco, uploadToCdn }
}

export default useExport
